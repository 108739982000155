<template lang="html">
  <div class="component-wrapper">
    <CreatePlaylist></CreatePlaylist>
  </div>
</template>

<script>
import CreatePlaylist from '@/components/playlists/CreatePlaylist.vue'

export default {
    components: {
        CreatePlaylist
    },
    mounted() {
        
    }
}
</script>

<style lang="css">
</style>
