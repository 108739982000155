<template lang="html">
    <div v-if="this.$store.state.isAuthenticated" class="component-wrapper row">
        <div class="create-playlist-wrapper col-lg-6 col-md-12">
            <h2 id="create-collection">Create a collection</h2>
            <div class="col-12">
                <b-form class="mb-2" method="post">
                    <div class="save-podcast-div mb-4">
                        <div class="create-podcast-name-line mb-2 d-flex">
                            <b-input class="form-control create-playlist-input"
                                   placeholder="Collection name"
                                   aria-label="Podcast name"
                                   v-model="playlistName">
                            </b-input>
                        </div>
                        <button id="save-playlist-name"
                                class="create-podcast-name-button mb-2"
                                type="submit"
                                name="button"
                                @click.prevent="initalizePlaylist">
                            Save collection!
                        </button>
                        <div class="create-playlist-info">
                            <p v-if="!showCreated">Give your collection a name!</p>
                            <p v-if="!showCreated">(You can change it later.)</p>
                            <p v-if="showCreated && !lastAdded">Nice! Now add some audio dramas to it.</p>
                            <div v-if="lastAdded">
                                <p>
                                    {{this.lastAdded}} added to collection!
                                </p>
                            </div>
                            <div v-else-if="justSaved">
                                <p>
                                    Collection saved!
                                </p>
                            </div>
                            <div v-else-if="alreadyExists">
                                <p>
                                    A collection with that name already exists. Please try another one!
                                </p>
                            </div>
                        </div>

                    </div>
                </b-form>
            <div class="col-12">
                    <b-form method="patch">
                        <div class="create-podcast-summary-div mb-2">
                            <p class="create-podcast-generic-header">Collection summary</p>
                            <b-form-textarea class="form-control create-playlist-input"
                                   placeholder="Describe this collection. What makes it special?"
                                   aria-label="Collection summary"
                                   v-model="playlistSummary"
                                   max-length="500"
                                   :disabled="!playlistId">
                            </b-form-textarea>
                        </div>
                        <p class="create-podcast-generic-header">Add podcasts to your collection</p>
                        <div class="div-for-dropdown-search">
                            <input class="form-control me-2 mb-2 create-playlist-input"
                                   placeholder="Search for podcasts"
                                   aria-label="Search"
                                   v-model="playlistPodcastSearchString"
                                   :disabled="!playlistId"
                                   v-debounce:600ms="searchForPodcast">
                            <div v-if="playlistPodcastSearchString.length > 0 && playlistPodcastSearchResults.data" class="dropdown-menu playlist-dropdown">
                                <div>
                                    <div @click="addToPlaylist(result)"
                                         class="navbar-single-result-container create-playlist d-flex"
                                         v-for="result in playlistPodcastSearchResults.data.results"
                                         :key="result.podcast_id">
                                        <p class="navbar-single-result-name">
                                            {{result.name}}
                                        </p>
                                        <b-dropdown-divider></b-dropdown-divider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </div>
        <div class="current-playlist col-lg-6 col-md-12">
            <h2 id="created-playlist-header" v-if="playlistCreated">{{playlistName}}</h2>
            <div v-for="(podcast, index) in podcastsInPlaylist.results"
                 class="playlist-podcasts search-result p-3 mb-3"
                 :key="index">
                 <div class="single-podcast-header-text col-md-3 col-9">
                     <p class="results-podcast-name"><strong>{{podcast.name}}</strong></p>
                     <p class="results-podcast-artist">{{podcast.artist}}</p>
                 </div>
                 <div class="col-md-7 d-none d-md-block p-2">
                     <small class="results-podcast-summary">
                         <p v-if="podcast.summary.length > 100">
                             {{podcast.summary.slice(0, 150)}}...
                         </p>
                         <p v-else>
                             {{podcast.summary}}
                         </p>
                     </small>
                 </div>
                 <div v-if="podcast.podcast_id" id="single-podcast-header-image" class="col-md-2 d-flex col-3 justify-content-end">
                     <b-img rounded fluid class="result-image float-end"
                            :src="require(`@/assets/img/${podcast.podcast_id}.jpg`)"
                            @error="$event.target.src('https://cdn.pixabay.com/photo/2020/02/12/05/13/dog-cartoon-4841702_960_720.jpg')">
                     </b-img>
                     <div
                          class="remove-from-playlist"
                          @click="removeFromPlaylist(podcast.podcast_id)">
                         <p>
                             X
                         </p>
                     </div>
                 </div>
            </div>

        </div>
    </div>
    <div v-else-if="!this.$store.state.isAuthenticated" class="not-authenticated">
        <p class="mb-2 need-to-be-logged-in">You need to be logged in to create a collection. </p>
        <button id="podcast-login-button" @click="$parent.$parent.showLogin =! $parent.$parent.showLogin">
            Login to create a collection
        </button>
    </div>
</template>

<script>
// import SearchResults from '@/components/search_results/SearchResults.vue'
export default {
    components: {
        // SearchResults
    },
    mounted() {
        this.setupUnloadListener()
    },
    beforeunload() {
        this.commitAndUpdatePlaylist()
    },
    data() {
        return {
            playlistPodcastSearchResults: {},
            playlistPodcastSearchString: '',
            podcastsInPlaylist: {
                'results': []
            },
            showDropDownSearch: true,
            playlistName: '',
            playlistId: null,
            canAddPodcasts: false,
            showCreated: false,
            playlistSummary: '',
            lastAdded: '',
            justSaved: false,
            alreadyExists: false,
            playlistCreated: false
        }
    },
    computed: {
        podcastsToSave() {
            let postPodcasts = []
            for (let podcast of this.podcastsInPlaylist.results) {
                postPodcasts.push({'podcast_id': podcast.podcast_id})
            }
            return postPodcasts
        }
    },
    methods: {
        setupUnloadListener() {
            window.addEventListener('beforeunload', (event) => {
                event.preventDefault()
                return this.commitAndUpdatePlaylist()
            })
        },
        searchForPodcast() {
            let queryUrl = `${this.$store.state.endpoints.baseUrl}podcasts/?name__contains=${this.playlistPodcastSearchString}`
            this.$http.get(queryUrl).then((response) => {
                this.playlistPodcastSearchResults = response
            })
        },
        addToPlaylist(result) {
            if (!this.podcastsInPlaylist.results.some(pod => pod.podcast_id === result.podcast_id)) {
                this.podcastsInPlaylist.results.push(result)
                this.playlistPodcastSearchString = ''
                this.lastAdded = result.name
                this.justSaved = false
                this.commitAndUpdatePlaylist()
            }
        },
        removeFromPlaylist(id) {
            this.podcastsInPlaylist.results.splice(this.podcastsInPlaylist.results.findIndex(({podcast_id}) => podcast_id === id), 1);
        },
        initalizePlaylist() {
            const base = {
                baseURL: this.$store.state.endpoints.baseUrl,
                headers: {
                    Authorization: `JWT ${this.$store.state.jwt}`,
                    'Content-Type': 'application/json',
                },
                xhrFields: {
                    withCredentials: true,
                },
            }
            const axiosInstance = this.$http.create(base);
            axiosInstance({
                url: `playlists/`,
                method: 'post',
                data: {'name': this.playlistName, 'podcasts': this.podcastsToSave, 'summary': this.playlistSummary},
            }).then((response) => {
                if (response.status === 201) {
                    this.showCreated = true
                    this.playlistId = response.data.id
                    this.alreadyExists = false
                    this.playlistCreated = true
                }
            }).catch((error) => {
                if (error.response.data.name[0] === 'playlist with this name already exists.') {
                    this.alreadyExists = true
                }
            })
        },
        getPlaylistInfo() {
            this.$http.get(`${this.$store.state.endpoints}playlists/${this.podcastId}`).then((response) => {
                console.log(response)
            })
        },
        commitAndUpdatePlaylist() {
            const base = {
                baseURL: this.$store.state.endpoints.baseUrl,
                headers: {
                    Authorization: `JWT ${this.$store.state.jwt}`,
                    'Content-Type': 'application/json',
                },
                xhrFields: {
                    withCredentials: true,
                },
            }
            const axiosInstance = this.$http.create(base);
            axiosInstance({
                url: `playlists/${this.playlistId}/`,
                method: 'patch',
                data: {'podcasts': this.podcastsToSave, 'summary': this.playlistSummary},
            }).then((response) => {
                this.playlistId = response.data.id
            })
            this.lastAdded = ''
            if (this.playlistName.length > 0) {
                this.justSaved = true
            }
        }
    }
}
</script>

<style lang="css" scoped>
.div-for-dropdown-search {
    position: relative;
}
.dropdown-menu.playlist-dropdown {
    display: block;
    max-width: 100%;
    color: #333333;
    right: auto;
    left: auto;
    position: absolute;
    top: 2.5em;
}

.create-playlist:hover {
    cursor: default !important;
    background: red;
}

.create-playlist-input {
    /* width: 40% !important; */
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 0px !important;
}

@media (max-width: 767px) {
    .create-playlist-input {
        width: 90% !important;
    }
    .dropdown-menu.playlist-dropdown {
        display: block;
        top: 135px;
        width: 100%;
        color: #333333;
        right: auto;
        left: auto;
    }
    .dropdown-menu.playlist-dropdown {
        display: block;
        max-width: 90%;
        color: #333333;
        right: auto;
        left: auto;
        position: absolute;
        top: 2.5em;
    }
}
.create-podcast-name-button {
    border-radius: 0.5em;
    box-shadow:  5px 5px 0px var(--darkBackground),
        -3px -3px 3px var(--hoverColor);
    border: none;
    background: #6998ff;
    color: white;
    padding: 0.5em;
}
.create-podcast-name-line {
    align-items: center;
}

.single-podcast-search-dropdown {

}

.add-podcast-to-playlist {
    margin-right: 1em;
    color: #7eb165;
    font-size: 2em;
    cursor: pointer;
}
.navbar-single-result-container.create-playlist {
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.navbar-single-result-container.create-playlist:hover {
    background: var(--hoverColor);
    color: white;
}
.current-playlist {
    overflow-y: scroll;
    max-height: 80vh;
    scrollbar-width: none;

}
.current-playlist::-webkit-scrollbar {
    display: none;
}
#podcast-login-button {
    padding: 1em;
    margin-bottom: 1em;
    border-radius: 1em;
    box-shadow: var(--globalButtonShadow);
    border: none;
    background: white;
    color: white;
    color: var(--darkBackground);
    font-weight: bold;
}
.playlist-update-info-div {
    border-radius: 1em;
    background: white;
    /* background: #5da34f; */
    color: var(--darkBackground);
    font-weight: bolder;
    padding: 1em;
    box-shadow: var(--globalButtonShadow);
    max-width: 80%;
}
.update-podcast-name-button {
    border-radius: 0.5em;
    box-shadow: var(--globalButtonShadow);
    border: none;
    background: #6998ff;
    color: white;
    padding: 0.5em;
    margin-top: 0.5em;
}
#save-playlist-name {
    margin-left: auto;
    margin-right: 0px !important;
    width: fit-content;
    width: -moz-fit-content;
}
.create-podcast-generic-header {
    font-size: 1.5em;
    color: white;
}
#created-playlist-header {
    font-size: 2em;
    margin-bottom: 0.5rem !important;
}
.results-podcast-artist {
    word-wrap: break-word;
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}
.create-playlist-info {
    box-shadow: var(--globalInwardShadow);
    padding: 1em;
    border-radius: 1em;
    color: white;
}
#create-collection {
    color: white;
}
.need-to-be-logged-in {
    color: white;
}
</style>
